import { FC, PropsWithChildren } from "react";
import Link from "next/link";
import { UrlObject } from "url";

interface Props {
  href: string | UrlObject;
  icon?: string;
  target?: "_blank" | "_self";
}

const HeaderNavigationItem: FC<PropsWithChildren<Props>> = ({
  children,
  href,
  icon,
  target = "_self",
}): JSX.Element => {
  return (
    <Link href={href} prefetch={false} legacyBehavior>
      {icon ? (
        <a className="flex items-center space-x-4 hover:no-underline leading-none">
          <i className={["laptop:text-lg text-sm", `icon-${icon}`].join(" ")} />
          <span>{children}</span>
        </a>
      ) : (
        <a
          className="flex items-center space-x-4 hover:no-underline leading-none"
          target={target}
        >
          {children}
        </a>
      )}
    </Link>
  );
};
export default HeaderNavigationItem;
