import React, { FC } from "react";
import clsx from "clsx";

interface DividerProps {
  direction?: "horizontal" | "vertical";
  className?: string;
}

const Divider: FC<DividerProps> = ({ direction = "horizontal", className }) => {
  return (
    <div
      className={clsx(
        "bg-gray-100", // border-primaryに相当する
        {
          "h-px w-full": direction === "horizontal",
          "w-px h-full": direction === "vertical",
        },
        className,
      )}
    />
  );
};

export default Divider;
